.header {
  display: flex;
  justify-content: space-between;
  padding: 36px 0 10px 0;
}

.phoneContainer {
  display: flex;
  align-items: center;
}

.phoneContainer a {
  text-decoration: none;
  color: #000000;
  font-size: 20px;
  font-weight: 700;
  margin-left: 14px;
}

.burger {
  width: 46px;
  height: 38px;
  display: none;
}

@media (max-width: 763px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: #FFFFFF;
    width: 80%;
    padding: 20px 0 14px 0;
    padding-left: 10%;
    padding-right: 10%;
  }
  .burger {
    display: block;
    margin-left: 20px;
    transition: all 0.5s ease-in;
  }
  .burgerLine {
    border: 2px solid #000000;
    margin: 6px 8px;
    border-radius: 2px;
    transition: all 0.5s ease-in;
  }
  .hidingLine {
    opacity: 0;
    transition: all 0.1s ease-in;
  }
  .firstLine {
    transform: rotate(45deg);
    transform-origin: 2px 7px;
    border: 2px solid #000000;
    margin: 6px 8px;
    border-radius: 2px;
    transition: all 0.5s ease-in;
  }
  .lastLine {
    transform: rotate(-45deg);
    border: 2px solid #000000;
    margin: 6px 8px;
    border-radius: 2px;
    transform-origin: 16px 2px;
    transition: all 0.5s ease-in;
  }
}

@media (max-width: 420px) {
  .header {
    width: 90%;
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (max-width: 350px) {
  .header {
    width: 96%;
    padding-left: 2%;
    padding-right: 2%;
  }
  .burger {
    margin-left: 10px;
  }
  .phoneContainer a {
    margin-left: 8px;
  }
}
