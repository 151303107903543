.App {
  display: flex;
  justify-content: center;
}

.Header {
  padding: 10px;
  font-weight: bold;
  font-style: italic;
  text-align: center;
}

.Client {
  margin-bottom: 10px;
}

.ClientName {
  font-weight: bold;
  text-align: center;
}

.Group {
  margin-bottom: 10px;
}

.GroupName {
  color: grey;
  padding: 5px 0 5px 0;
}

.UnitName {
  color: grey;
  padding: 5px 0 5px 0;
}

.Unit {
  padding-left: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 2px lightgrey dashed;
}

.Result {
  padding: 5px 0 5px 0;
}

.ResultScore {
  font-weight: bold;
}
