.navUl {
  margin: 0;
  padding: 0;
}

.navUl li:last-child>div {
  border-bottom: none;
}

.navBox {
  padding-top: 20px;
  overflow-y: scroll;
}

.navBox::-webkit-scrollbar {
  width: 0;
}

@media (max-width: 763px) {
  .navBox {
    padding-top: 0;
    border-radius: 0 0 15px 15px;
  }
}
