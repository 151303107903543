.review {
  position: relative;
  border: 1px solid black;
  border-radius: 20px;
  padding: 20px 20px 20px 40px;
  font-size: 15px;
  margin: 20px 0 10px 40px;
}

.reviewName {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 10px;
}

.reviewLabel {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  left: -26px;
  font-size: 10px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.perfect {
  background: #FFEE02;
}

.good {
  background: #CDF576;
}

.notBad {
  background: #E2C8FA;
}



