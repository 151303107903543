.main {
  max-width: 530px;
  display: block;
  margin: 0 auto;
  padding-top: 35px;
}

.name {
  font-weight: 700;
  font-size: 35px;
  margin-bottom: 22px;
}

.contactData {
  display: flex;
  justify-content: space-between;
  font-weight: 350;
  font-size: 17px;
  color: #808080;
  margin-bottom: 8px;
}

.classesData {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 21px;
  margin-bottom: 25px;
}

.learningData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 50px;
}

.learningData_total {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 38px;
  border-radius: 20px;
  background-color: #cdf576;
}

.learningData_count {
  font-weight: 700;
  font-size: 17px;
}

.diagrams {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 45px;
}

@media (max-width: 763px) {
  .main {
    padding-top: 100px;
  }
}

@media (max-width: 550px) {
  .main {
    max-width: 380px;
  }
  .learningProgram {
    max-width: 250px;
  }
}

@media (max-width: 420px) {
  .main {
    max-width: 350px;
  }
  .learningData {
    font-size: 26px;
  }
  .learningData_count {
    font-size: 14px;
  }
}

@media (max-width: 358px) {
  .main {
    max-width: 300px;
  }
  .classesData {
    justify-content: space-around;
  }
  .classesDataCount {
    text-align: right;
  }
}
