.subsubmenuBox {
  padding-left: 0;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  background: #FBFBFB;
}

.activeGroup {
  background: #B3B3B3 !important;
}

/*groups*/
.submenu {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #cbcbcb;
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  border: none;
  height: 43px;
  padding-left: 71px;
  width: inherit;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(90deg, rgba(211,255,255,0) 0%, rgba(255,255,255,0) 10%, rgba(211,211,211,1) 10%, rgba(211,211,211,1) 90%, rgba(255,255,255,0) 90%, rgba(255,255,255,0) 100%);
  border-image-slice: 1;
}

.subsublink {
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #999;
  font-size: 14px;
  font-weight: 400;
  height: 43px;
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(90deg, rgba(211,255,255,0) 0%, rgba(255,255,255,0) 10%, rgba(211,211,211,1) 10%, rgba(211,211,211,1) 90%, rgba(255,255,255,0) 90%, rgba(255,255,255,0) 100%);
  border-image-slice: 1;
  padding-left: 71px;
}

.subsublink:last-child {
  border-image: none;
}

