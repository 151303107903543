.SideBar {
  display: flex;
  width: 318px;
  height: 100vh;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 0 20px 20px 0;
  transition: 1s;
  background-color: #FBFBFB;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.UserIcon {
  margin: 21px 19px;
  width: 52px;
  height: 52px;
  transition: 1s;
}

.NavBar {
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
}

.NavBarItem {
  display: flex;
  width: 100%;
}

.FooterBtnBox {
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: end;
  position: absolute;
  bottom: 0;
  border-radius: 20px;
  height: 0;
}

.OpenCloseBtn {
  width: 17px;
  height: 14px;
  margin: 20px;
}

button {
  border: 0;
  background: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 763px) {
  .SideBar {
    display: none;

  }
}

