.mobileMenu {
  display: none;
}
.showMobileMenu {
  display: none;
}

@media (max-width: 763px) {
  .mobileMenu {
    display: block;
    position: absolute;
    top: 72px;
    right: -764px;
    width: 100%;
    height: 200px;
    z-index: 1;
    transition: all 0.5s ease-in;
  }
  .showMobileMenu {
    display: block;
    position: absolute;
    top: 72px;
    right: 0;
    width: 100%;
    height: calc(100vh - 72px);
    overflow-y: scroll;
    z-index: 1;
    transition: all 0.5s ease-in;
  }
}


