.diagram {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 152px;
  margin: 10px;
}

.diagramDescription {
  text-align: center;
}
