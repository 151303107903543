.inactive {
  display: flex;
  cursor: pointer;
  height: 66px;
  padding-left: 46px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid transparent;
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  text-decoration: none;
  border-image:
    linear-gradient(90deg,
    rgba(255,255,255,0) 0%,
    rgba(255,255,255,0) 10%,
    rgba(0,0,0,1) 10%,
    rgba(0,0,0,1) 90%,
    rgba(255,255,255,0) 90%,
    rgba(255,255,255,0) 100%);
  border-image-slice: 1;
  background: linear-gradient(90deg, #FFED00 0%, #FCF6A6 38.46%, #FBFBFB 100%);
}

.active {
  display: flex;
  height: 66px;
  padding-left: 46px;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid transparent;
  color: #000000;
  font-weight: 700;
  font-size: 18px;
  cursor: pointer;
  text-decoration: none;
  background: linear-gradient(90deg, #FFED00 0%, #FFED00 3%, #FBFBFB 3%, #FBFBFB 100%);
}
